.login-dark {
    height:100%;
    background-size:cover;
    position:relative;
    
  }
  
  .login-dark form {

    background-color: #FFFFFF;
    /*padding-top: 42px;
    padding-bottom: 15px;
*/
    max-width:320px;
    width:90%;
    padding:40px;
    border-radius:30px;
    transform:translate(-50%, -80%);
    position:absolute;
    top:80%;
    left:50%;
    margin-top:25em;
    
    
    color:#000;  
    box-shadow:7px 7px 7px rgba(0,0,0,0.2);
  }
  
  .login-dark .illustration {
    text-align:center;
    padding:15px 0 20px;
    font-size:100px;
    color:#2980ef;
  }
  
  .login-dark form .form-control {
    background:none;
    border:none;
    border-bottom:1px solid #434a52;
    border-radius:0;
    box-shadow:none;
    outline:none;
    color:inherit;
  }
  
  .login-dark form .btn-primary {
    background:#214a80;
    border:none;
    border-radius:4px;
    padding:11px;
    box-shadow:none;
    margin-top:26px;
    text-shadow:none;
    outline:none;
  }
  
  .login-dark form .btn-primary:hover, .login-dark form .btn-primary:active {
    background:#214a80;
    outline:none;
  }
  
  .login-dark form .forgot {
    display:block;
    text-align:center;
    font-size:12px;
    color:#6f7a85;
    opacity:0.9;
    text-decoration:none;
  }
  
  .login-dark form .forgot:hover, .login-dark form .forgot:active {
    opacity:1;
    text-decoration:none;
  }
  
  .login-dark form .btn-primary:active {
    transform:translateY(1px);
  }
  
  